<!-- 
	This is an empty page for layout demonstrations, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

<template>
    <div>


        <sweet-modal ref="createuploadmodal" @close="closeNested" title="Upload ID" width="60%">
            <a-form
                id="components-form-demo-normal-login"
                :form="form"
                class="login-form"
                @submit="handleSubmitUpload"
                :hideRequiredMark="false"
                v-if="showNested"
            >
        
                <a-form-item class="mb-10" label="Select ID Type" :colon="true"  >

            
                
                    <b-form-select  v-model="selectedIdType" required>

                        <b-form-select-option v-for="idType in idTypes" :value="idType.value" :key="idType.value">
                            {{ idType.label }}
                        </b-form-select-option>

                    </b-form-select>
                </a-form-item>

                <a-form-item class="mb-10" label="Upload Image" :colon="true" >
                
                    <input
                        type="file"
                        ref="uploadFile"
                        :multiple="false"
                        :accept="'image/jpeg,image/png'"
                        @change="handleUploadChange"
                    />
                        
                    

                </a-form-item>

                <a-form-item>
                    <a-button type="primary" block html-type="submit" class="login-form-button" :disabled="loading||!selectedIdType||!uploadFile">
                        Upload ID
                    </a-button>

                    <vue-loaders-ball-beat v-if="loading" color="red" scale="1"></vue-loaders-ball-beat>

                    <p class="font-semibold text-muted text-center" v-if="error">{{ error }}</p>

                </a-form-item>

            
        </a-form>

        </sweet-modal>

        <vue-loaders-ball-beat v-if="loading" color="red" scale="1"></vue-loaders-ball-beat>


        <p>This is a summary of the business.
            You can look at each section independently to understand the score of the business.
            The general information tab gives some basic information about the business including identification
            and phone number. The KYC portion will be filled in only after the KYC process has been completed
            for the SME. The Comment outlines the summary information generated by our AI and shows any gaps identified
            by the system.
        </p>



        <a-tabs default-active-key="1">

            <a-tab-pane key="1" tab="General Information" force-render>

                <a-card v-if="!loading">
                    
                        <h4 class="mb-15">Name: {{ sme.info.first_name }} {{ sme.info.last_name }}</h4>
                        <h6 class="font-regular text-muted"><b>Phone:</b> {{ sme.info.phone }}</h6>
                        <h6 class="font-regular text-muted"><b>Address: </b>{{ sme.info.address }}</h6>
                        <h6 class="font-regular text-muted"><b>ID Number:</b> {{ sme.info.id_number }}</h6>
                        <h6 class="font-regular text-muted"><b>Gender:</b> {{ sme.info.gender }}</h6>
                        <h6 class="font-regular text-muted"><b>Statement Email:</b> {{ sme.info.parse_email }}</h6>
                        <h6 class="font-regular text-muted"><b>Last Active:</b> {{ sme.info.last_active }}</h6>
                        <h6 class="font-regular text-muted"><b>Created At:</b> {{ sme.info.created_at }}</h6>
                        <h6 class="font-regular text-muted"><b>Updated At:</b> {{ sme.info.updated_at }}</h6>
                        <h6 class="font-regular text-muted"><b>Current Project:</b> {{ sme.info.project_name }}</h6>
                    
                </a-card>

            </a-tab-pane>

            <a-tab-pane key="2" tab="KYC Status" force-render>

                <a-card v-if="!loading">

                    <a-row>

                        <a-col :span="4" class="mb-24">
                            <h6 class="mb-15">KYC Done: </h6>

                        </a-col>

                        <a-col :span="12" class="mb-24">
                            <i class="fa fa-check" aria-hidden="true" style="font-size: 23px; color: green;"
                                v-if="sme.kyc.kyc_done"></i>

                            <i class="fa fa-ban" aria-hidden="true" style="font-size: 23px; color: red;"
                                v-if="!sme.kyc.kyc_done"></i>

                        </a-col>

                    </a-row>

                    <a-row>

                        <a-col :span="4" class="mb-24">
                            <h6 class="mb-15">KYC Score: </h6>
                            <span style="margin-left: 7px; font-size: 13px;">{{ sme.kyc.kyc_score }} / 10</span>
                        </a-col>

                        <a-col :span="12" class="mb-24">
                            <progress id="file" :value="sme.kyc.kyc_score" max="10"></progress>

                        </a-col>

                    </a-row>

                    <a-row>

                        <a-col :span="4" class="mb-24">
                            <h6 class="mb-15">KYC Passed: </h6>

                        </a-col>

                        <a-col :span="12" class="mb-24">
                            <i class="fa fa-check" aria-hidden="true" style="font-size: 23px; color: green;"
                                v-if="sme.kyc.kyc_passed"></i>

                            <i class="fa fa-ban" aria-hidden="true" style="font-size: 23px; color: red;"
                                v-if="!sme.kyc.kyc_passed"></i>

                        </a-col>

                    </a-row>


                    <a-row>

                        <a-col :span="4" class="mb-24">
                            <h6 class="mb-15">KYC Comment: </h6>

                        </a-col>

                        <a-col :span="12" class="mb-24">
                            <span style="font-size: 13px;">{{ sme.kyc.comment }}</span>

                        </a-col>

                    </a-row>


                </a-card>

            </a-tab-pane>

            <a-tab-pane key="3" tab="CRB Score" force-render>

                <a-card v-if="!loading">

                    <a-row>

                        <a-col :span="4" class="mb-24">
                            <h6 class="mb-15">CRB Done: </h6>

                        </a-col>

                        <a-col :span="12" class="mb-24">
                            <i class="fa fa-check" aria-hidden="true" style="font-size: 23px; color: green;"
                                v-if="sme.crb.crb_done"></i>

                            <i class="fa fa-ban" aria-hidden="true" style="font-size: 23px; color: red;"
                                v-if="!sme.crb.crb_done"></i>

                        </a-col>

                    </a-row>

                    <a-row>

                        <a-col :span="4" class="mb-24">
                            <h6 class="mb-15">CRB Score: </h6>
                            <span style="margin-left: 7px; font-size: 13px;">{{ sme.crb.crb_score }} / 10</span>
                        </a-col>

                        <a-col :span="12" class="mb-24">
                            <progress id="file" :value="sme.crb.crb_score" max="10"></progress>

                        </a-col>

                    </a-row>



                    <a-row>

                        <a-col :span="4" class="mb-24">
                            <h6 class="mb-15">CRB Comment: </h6>

                        </a-col>

                        <a-col :span="12" class="mb-24">
                            <span style="font-size: 13px;">{{ sme.crb.comment }}</span>

                        </a-col>

                    </a-row>

                    <a-row>

                        <a-col :span="4" class="mb-24">
                            <h6 class="mb-15">Highest Borrowed: </h6>

                        </a-col>

                        <a-col :span="12" class="mb-24">
                            <span style="font-size: 13px;">{{ sme.crb.highest_borrowed_amount }}</span>

                        </a-col>

                    </a-row>



                    <a-row>

                        <a-col :span="4" class="mb-24">
                            <h6 class="mb-15">Highest Days Arrears: </h6>

                        </a-col>

                        <a-col :span="12" class="mb-24">
                            <span style="font-size: 13px;">{{ sme.crb.highest_days_in_arrears }}</span>

                        </a-col>

                    </a-row>



                    <a-row>

                        <a-col :span="4" class="mb-24">
                            <h6 class="mb-15">Credit Score: </h6>

                        </a-col>

                        <a-col :span="12" class="mb-24">
                            <span style="font-size: 13px;">{{ sme.crb.credit_score }} / 900</span>

                        </a-col>

                    </a-row>


                </a-card>
            </a-tab-pane>
            

            <a-tab-pane key="7" tab="Allocations" force-render>
 
                <a-card title="Allocations" style="width: 100%">
                    <a-table :columns="allocColumns" :dataSource="allocations" :pagination="false" :loading="loading" rowKey="id">
                        <span slot="amount" slot-scope="text, record">
                            {{ record.amount }}
                        </span>

                        <span slot="is_paid" slot-scope="text, record">
                            

                            <i class="fa fa-check" aria-hidden="true" style="font-size: 23px; color: green;"
                                v-if="record.is_paid"></i>

                            <i class="fa fa-ban" aria-hidden="true" style="font-size: 23px; color: red;"
                                v-if="!record.is_paid"></i>
                        </span>

                        <span slot="is_used" slot-scope="text, record">
                            

                            <i class="fa fa-check" aria-hidden="true" style="font-size: 23px; color: green;"
                                v-if="record.is_used"></i>

                            <i class="fa fa-ban" aria-hidden="true" style="font-size: 23px; color: red;"
                                v-if="!record.is_used"></i>
                        </span>

                        <span slot="project_name" slot-scope="text, record">
                            {{ record.project_name }}
                        </span>
                        <span slot="created_at" slot-scope="text, record">
                            {{ record.created_at }}
                        </span>
                        <span slot="actions" slot-scope="text, record">
                            <a-button type="primary">View</a-button>
                        </span>
                    </a-table>
                </a-card>


            </a-tab-pane>

            <a-tab-pane key="6" tab="ID Uploads" force-render>

                <div v-if="!loading">
                    
                    <b-row >
                        <a-col :span="6">

                            <a-card title="ID front" style="width: 300px">
                                <img v-if="docs.front_doc" :src="docs.front_doc" style="width: 100%" />
                                <span v-else>No image uploaded</span>
                            </a-card>

                        </a-col>

                        <a-col :span="6">

                            <a-card title="ID back" style="width: 300px">
                                <img v-if="docs.back_doc" :src="docs.back_doc" style="width: 100%;" />
                                <span v-else>No image uploaded</span>
                            </a-card>

                        </a-col>
                       
                    </b-row>

                    <b-row>
                        <a-button type="primary" @click="startUpload">Upload ID</a-button>
                    </b-row>
                    
                </div>

                
            </a-tab-pane>


            <a-tab-pane key="4" tab="Statements Score" force-render>

                <a-card v-if="!loading">

                    <a-row>

                        <a-col :span="4" class="mb-24">
                            <h6 class="mb-15">Statement Done: </h6>

                        </a-col>

                        <a-col :span="12" class="mb-24">
                            <i class="fa fa-check" aria-hidden="true" style="font-size: 23px; color: green;"
                                v-if="sme.statement.statement_done"></i>

                            <i class="fa fa-ban" aria-hidden="true" style="font-size: 23px; color: red;"
                                v-if="!sme.statement.statement_done"></i>

                        </a-col>

                    </a-row>

                    <a-row>

                        <a-col :span="4" class="mb-24">
                            <h6 class="mb-15">Credit Limit: </h6>
                        </a-col>

                        <a-col :span="12" class="mb-24">
                            <span style="margin-left: 7px; font-size: 13px;">{{ sme.statement.current_credit_limit.toFixed(1) }}</span>

                        </a-col>

                    </a-row>

                    <a-row>

                        <a-col :span="4" class="mb-24">
                            <h6 class="mb-15">Available Credit: </h6>
                        </a-col>

                        <a-col :span="12" class="mb-24">
                            <span style="margin-left: 7px; font-size: 13px;">{{ sme.statement.available_credit.toFixed(1) }}</span>

                        </a-col>

                    </a-row>



                    <a-row>

                        <a-col :span="4" class="mb-24">
                            <h6 class="mb-15">Previous Loans Found: </h6>

                        </a-col>

                        <a-col :span="12" class="mb-24">
                            <span style="font-size: 13px;">{{ sme.statement.loans_found }}</span>

                        </a-col>

                    </a-row>

                    <a-row>

                        <a-col :span="4" class="mb-24">
                            <h6 class="mb-15">Average Paid In: </h6>

                        </a-col>

                        <a-col :span="12" class="mb-24">
                            <span style="font-size: 13px;">{{ sme.statement.average_paid_in.toFixed(1) }}</span>

                        </a-col>

                    </a-row>



                    <a-row>

                        <a-col :span="4" class="mb-24">
                            <h6 class="mb-15">Average Repayments: </h6>

                        </a-col>

                        <a-col :span="12" class="mb-24">
                            <span style="font-size: 13px;">{{ sme.statement.average_repayments.toFixed(1) }}</span>

                        </a-col>

                    </a-row>



                    <a-row>

                        <a-col :span="4" class="mb-24">
                            <h6 class="mb-15">Analysis Date: </h6>

                        </a-col>

                        <a-col :span="12" class="mb-24">
                            <span style="font-size: 13px;">{{ sme.statement.statement_date }}</span>

                        </a-col>

                    </a-row>


                </a-card>
            </a-tab-pane>

            <a-tab-pane key="5" tab="Production History" force-render>
                No information to display at the moment
            </a-tab-pane>

        </a-tabs>




    </div>
</template>

<script>

import Gauge from '@chrisheanan/vue-gauge';

import { register } from 'vue-advanced-chat'
register()

// "Projects" table component.
import WidgetStats from '../components/Widgets/WidgetStats';

import WidgetWhatsappChannelSetupVue from '../components/Widgets/WidgetWhatsappChannelSetup.vue';

import SimpleChat from '../components/Chat/SimpleChat';

import { fetchDataJSON, fetchPostJSON, uploadForm } from '../services/v1/services';

export default ({
    components: {
        WidgetStats,
        SimpleChat,
        WidgetWhatsappChannelSetupVue,
        Gauge
    },
    async mounted() {
        // ${this.$route.params.id}


        // this.$route.params.id;

        console.log(this.$route.params.id);


        const bizRes = await fetchDataJSON('aggregators/smes/' + this.$route.params.id + '/summary/view', true);

        const docsRes = await fetchDataJSON('aggregators/smes/' + this.$route.params.id + '/docs/view', true);

        const allocsRes = await fetchDataJSON('aggregators/smes/' + this.$route.params.id + '/allocations/list', true);

        this.sme = bizRes.data;

        this.docs = docsRes.data.docs;

        this.allocations = allocsRes.data.allocations;

        this.loading = false;

    },
    beforedestroy() {

    },
    methods: {
        startUpload() {
            this.showNested = true;
            this.$refs.createuploadmodal.open();
        },
        async beforeUpload(file) {
            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
            if (!isJpgOrPng) {
                this.$notification.error({
                    message: 'Error',
                    description: 'You can only upload JPG/PNG file!',
                });
            }
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isLt2M) {
                this.$notification.error({
                    message: 'Error',
                    description: 'Image must smaller than 2MB!',
                });
            }
            return isJpgOrPng && isLt2M;
        },
        async handleUploadChange(info) {
            console.log('handle upload change')


            const file = info.target.files[0];


            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';

            if (!isJpgOrPng) {
                this.$notification.error({
                    message: 'Error',
                    description: 'You can only upload JPG/PNG file!',
                });
            }

            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isLt2M) {
                this.$notification.error({
                    message: 'Error',
                    description: 'Image must smaller than 2MB!',
                });
            }
            
            this.uploadFile = file;

            console.log(file);
        },
        async closeNested() {
            this.showNested = false;
        },
        async handleSubmitUpload(e) {
            e.preventDefault();

            // validate form

            this.form.validateFields(async (err, values) => {
                if (!err) {
                    console.log('Received values of form: ', values);

                    this.loading = true;

                    this.error = null;

                    const formData = new FormData();

                    formData.append('doc_type', this.selectedIdType);

                    formData.append('file', this.uploadFile);


                    const uploadRes = await uploadForm('aggregators/smes/' + this.$route.params.id + '/docs/upload?doc_type=' + this.selectedIdType, formData, true);

                    console.log(uploadRes);

                    if (uploadRes.Ok) {
                        this.$notification.success({
                            message: 'Success',
                            description: 'File uploaded successfully',
                        });

                        this.loading = false;

                        this.$refs.createuploadmodal.close();

                    } else {
                        this.error = uploadRes.status;
                        this.$notification.error({
                            message: 'Error',
                            description: 'Failed to upload file:' + uploadRes.status,
                        });
                        this.loading = false;
                    }

                }
            });
        },
        async onSearch() {
            this.searchLoading = true;

            this.selectedRoomId = null;

            console.log(this.searchTerm.value);



            this.searchLoading = false;

        },
        async saveChannelData() {
            this.processing = true;

            const channelRes = await fetchPostJSON('projects/channels/' + this.$route.params.id + '/configure', this.channel_data, true);

            console.log(channelRes);

            this.processing = false;
        },
        async sendChannelListenRequest() {

            if (this.channel_data.channel_type == 'voice' && this.channel_data.channel_provider == 'twilio' && (this.channel_data.account_sid == null || this.channel_data.api_key == null)) {
                this.$notification.error({
                    message: 'Error',
                    description: 'Please set up the necessary credentials first.',
                });
                return;
            }


            this.listenProcessing = true;

            console.log('sendChannelListenRequest');

            this.$eventHub.$emit("newCallDeviceRequest", {
                channel: this.$route.params.id,
                channel_data: this.channel_data,
            });


        },
    },
    data() {
        return {
            channel_data: {},
            general_stats: [],
            loading: true,
            listenProcessing: false,
            name: '',
            description: '',
            channel_type: '',
            processing: false,

            sme: null,
            searchLoading: false,

            searchTerm: {
                value: '',
            },

            idTypes : [
                {
                    value: 'id_front',
                    label: 'National ID Front',
                },
                {
                    value: 'id_back',
                    label: 'National ID Back',
                },
                
            ],
            allocColumns: [
                {
                    title: 'Project Name',
                    dataIndex: 'project_name',
                    key: 'project_name',
                    scopedSlots: { customRender: 'project_name' },
                },
                {
                    title: 'Amount',
                    dataIndex: 'amount',
                    key: 'amount',
                    scopedSlots: { customRender: 'amount' },
                },
                {
                    title: 'Paid',
                    dataIndex: 'is_paid',
                    key: 'is_paid',
                    scopedSlots: { customRender: 'is_paid' },
                },

                {
                    title: 'Used',
                    dataIndex: 'is_used',
                    key: 'is_used',
                    scopedSlots: { customRender: 'is_used' },
                },

                {
                    title: 'Created At',
                    dataIndex: 'created_at',
                    key: 'created_at',
                    scopedSlots: { customRender: 'created_at' },
                },
                /*{
                    title: 'Actions',
                    dataIndex: 'actions',
                    key: 'actions',
                    scopedSlots: { customRender: 'actions' },
                },*/
            ],
            showNested: false,
            selectedIdType: 'id_front',
            fileList: [],
            form: this.$form.createForm(this),
            error: null,
            uploadFile: null,
            docs: null,
            allocations: [],

        }
    },
})

</script>

<style lang="scss">

input[type=file] {
  width: 100%;
  padding: 12px 20px;
  margin: 0px 0;
  box-sizing: border-box;

}

</style>