<!-- 
	This is the tables page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

 <template>
	<div>


        <sweet-modal ref="createproductmodal" @close="closeNested" title="Create Product">
            <a-form
                id="components-form-demo-normal-login"
                :form="form"
                class="login-form"
                @submit="handleSubmitProduct"
                :hideRequiredMark="true"
                v-if="showNested"
            >
                <a-form-item class="mb-10" label="Name" :colon="false">
                    <a-input 
                    v-decorator="[
                    'name',
                    { rules: [{ required: true, message: 'Please input your product name!' }] },
                    ]" placeholder="Name" />
                </a-form-item>
                <a-form-item class="mb-5" label="Price" :colon="false">
                    <a-input
                    v-decorator="[
                    'product_price',
                    { rules: [{ required: true, message: 'Please input your product_price!' }] },
                    ]" type="number" placeholder="product_price" />
                </a-form-item>
                <br>
                <a-form-item class="mb-10" label="Code" :colon="false">
                    <a-input 
                    v-decorator="[
                    'product_code',
                    { rules: [{ required: true, message: 'Please input your code!' }] },
                    ]" placeholder="Code" />
                </a-form-item>

                <a-form-item>
                    <a-button type="primary" block html-type="submit" class="login-form-button" :disabled="loading">
                        CREATE
                    </a-button>

                    <vue-loaders-ball-beat v-if="loading" color="red" scale="1"></vue-loaders-ball-beat>

                    <p class="font-semibold text-muted text-center" v-if="error">{{ error }}</p>

                </a-form-item>
            </a-form>
        </sweet-modal>

        

        <a-row :gutter="24" type="flex">

            <vue-loaders-ball-beat v-if="loading" color="red" scale="1"></vue-loaders-ball-beat>

            <a-col :span="20" class="mb-24">
                <!-- Header Search Input -->
					<a-input-search class="header-search" :class="searchLoading ? 'loading' : ''" placeholder="Type here…" @search="onSearch" :loading='searchLoading' v-model="searchTerm">
						<svg slot="prefix" width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path fill-rule="evenodd" clip-rule="evenodd" d="M8 4C5.79086 4 4 5.79086 4 8C4 10.2091 5.79086 12 8 12C10.2091 12 12 10.2091 12 8C12 5.79086 10.2091 4 8 4ZM2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8C14 9.29583 13.5892 10.4957 12.8907 11.4765L17.7071 16.2929C18.0976 16.6834 18.0976 17.3166 17.7071 17.7071C17.3166 18.0976 16.6834 18.0976 16.2929 17.7071L11.4765 12.8907C10.4957 13.5892 9.29583 14 8 14C4.68629 14 2 11.3137 2 8Z" fill="#111827"/>
						</svg>
					</a-input-search>
            </a-col>

            <a-col :span="4" class="mb-24">
                <a-button type="primary" @click="initCreateProductModal" block style="margin-left: 9px;">
                    Add Product
                </a-button>
            </a-col>
            
        </a-row>

        

		<!-- Authors Table -->
		<a-row :gutter="24">

            <p v-if="project_products.length<1">No items to display, check that you have data sources configured.</p>

            <a-col :span="24">
                <a-table :columns="productColumns" :dataSource="project_products" :pagination="false" :loading="loading" rowKey="id">
                    <span slot="name" slot-scope="text, record">
                        {{ record.name }}
                    </span>
                    <span slot="description" slot-scope="text, record">
                        {{ record.product_code }}
                    </span>
                    <span slot="price" slot-scope="text, record">
                        {{ record.product_price }}
                    </span>
                    <span slot="created_at" slot-scope="text, record">
                        {{ record.created_at }}
                    </span>
                    <span slot="actions" slot-scope="text, record">
                        <a-button type="primary"
                        @click="$notification.info({message: 'View', description: 'Viewing ' + record.name + ' details..coming soon..'})"
                        >View</a-button>
                       
                    </span>
                </a-table>
            </a-col>

		</a-row>
		<!-- / Authors Table -->

        <br>

        <a-row>
            <a-col :span="24">
                <a-pagination :disabled="searchTerm.length>2" :total="total_project_products" :defaultPageSize="10" @change="onPageChange" :currentPage="currentPage"/>
            </a-col>
        </a-row>
	</div>
</template>

<script>

	// "Authors" table component.
	import CardProjectDisplay from '../components/Cards/CardProjectDisplay' ;

 	// "Projects" table component.
	import WidgetProduct from '../components/Widgets/WidgetProduct' ;

    import {fetchDataJSON, fetchPostJSON} from '../services/v1/services';
	

	export default ({
		components: {
			CardProjectDisplay,
			WidgetProduct,
		},
        beforeCreate() {
			// Creates the form and adds to it component's "form" property.
			this.form = this.$form.createForm(this, { name: 'normal_login' });
		},
        async mounted() {



            const projectProductsRes = await fetchDataJSON('aggregators/' + 'products/list' , true);

            this.project_products = projectProductsRes.data.products;

            this.project_total_products = 10;

            this.loading = false;
        },
        methods: {
            initCreateProductModal() {
                this.showNested = true;
                this.$refs.createproductmodal.open();
            }, 
            async handleSubmitProduct(e) {
                e.preventDefault();
                
                // validate form

                this.form.validateFields(async(err, values) => {
                    if ( !err ) {
                        console.log('Received values of form: ', values) ;

                        this.loading = true;

                        this.error = null;

                        const item = {
                            name: values.name,
                            product_price: values.product_price,
                            product_code: values.product_code,
                        };

                        const res = await fetchPostJSON('aggregators/products/create',item, true);

                        console.log(res);

                        this.loading = false;


                        if(res.Ok) {

                            console.log('ok')

                            
                        }
                        else{
                            this.$notification.error({
                            message: 'Error',
                            description: 'failed to create SME',
                            });
                        }

                        this.$refs.createproductmodal.close();

                        const projectProductsRes = await fetchDataJSON('aggregators/' + 'products/list?page=' + this.currentPage , true);

                        this.project_products = projectProductsRes.data.products;

                    }
                });

                // await this.addProject();
            },
            initCreateProjectChannelModal() {
                this.showNested = true;
                this.$refs.createprojectchannelmodal.open();
            },
            closeNested() {
                this.showNested = false;
            },
            async onPageChange(page) {
                console.log('onPageChange', page);

                this.loading = true;

                this.currentPage = page;

                const projectProductsRes = await fetchDataJSON('aggregators/' + 'products/list?page=' + page , true);

                this.project_products = projectProductsRes.data.products;

                this.loading = false;
            },
            async onSearch(value) {
                this.searchLoading = true;

                console.log('searching', value);

                const projectProductsRes = await fetchDataJSON('aggregators/' + 'products/list?term=' + value , true);

                this.project_products = projectProductsRes.data.project_products;

                this.searchLoading = false;
            },
        },
		data() {
			return {

                project_products: [],

                currentPage: 1,

                loading: true,

                error: null,

                searchLoading: false,

                showNested: false,

                productColumns: [
                    {
                        title: 'Name',
                        dataIndex: 'name',
                        key: 'name',
                        scopedSlots: { customRender: 'name' },
                    },
                    {
                        title: 'Description',
                        dataIndex: 'description',
                        key: 'description',
                        scopedSlots: { customRender: 'description' },
                    },
                    {
                        title: 'Price',
                        dataIndex: 'price',
                        key: 'price',
                        scopedSlots: { customRender: 'price' },
                    },
                    {
                        title: 'Created At',
                        dataIndex: 'created_at',
                        key: 'created_at',
                        scopedSlots: { customRender: 'created_at' },
                    },
                    
                    {
                        title: 'Actions',
                        dataIndex: 'actions',
                        key: 'actions',
                        scopedSlots: { customRender: 'actions' },
                    }
                    
                ],

                selectedChannelType: 'voice',

                selectedChannelProvider: 'twilio',

                newProjectName: '',

                newProjectDescription: '',

                searchTerm: '',

                project_total_products: 0,

                total_project_products: 0
			}
		},
	})

</script>

<style lang="scss">

.selector-thick {
    width: 100%;

    height: 40px
}
</style>