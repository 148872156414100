<!-- 
	This is the tables page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

 <template>
	<div>

 
        <sweet-modal ref="createprojectmodal" @close="closeNested" title="Create Allocation" width="60%">
            <a-form
                id="components-form-demo-normal-login"
                :form="form"
                class="login-form"
                @submit="handleSubmitProject"
                :hideRequiredMark="true"
                v-if="showNested"
            >
               
                <a-form-item class="mb-5" label="Items" :colon="false">
                    

                    <a-descriptions :bordered="true" :column="1">
                        <a-descriptions-item label="Product To Add">
                            <b-form-select v-model="selectedProduct" :options="products" value-field="id"
              		text-field="name" placeholder="Select Product" class="selector-thick"/>
                        </a-descriptions-item>

                        <a-descriptions-item label="Add To List">

                            <a-button type="primary" @click="addItem" :disabled="!selectedProduct">Add To List</a-button>

                        </a-descriptions-item>
                        </a-descriptions>

                </a-form-item>
                <a-form-item class="mb-10">
                    <a-descriptions :bordered="true" :column="1">
                        <a-descriptions-item label="Amount">
                            <a-input-number v-model="selectedTotal" :min="0" :max="1000000" disabled />
                        </a-descriptions-item>
                    </a-descriptions>
                   
                </a-form-item>
                <a-form-item class="mb-10">

                    <a-descriptions :bordered="true" :column="1">
                        <a-descriptions-item label="Select SME">
                            <b-form-select v-model="selectedSME" :options="smes" value-field="id"
              		text-field="first_name" placeholder="Select Project Type" class="selector-thick"/>
                        </a-descriptions-item>
                        </a-descriptions>

                </a-form-item>


                <a-form-item class="mb-5" label="Items" :colon="false">
                    <ul>
                        <li v-for="item in selectedItems" :key="item.id">
                            {{ item.name }} @ {{ item.product_price }} <a-input-number style="margin-left: 9px;" v-model="item.qty" :min="0" :max="1000000" /> <a-button type="danger" @click="removeItem($index)">Remove</a-button>
                        </li>
                    </ul>
                </a-form-item>

                <a-form-item>
                    <a-button type="primary" block html-type="submit" class="login-form-button" :disabled="loading||selectedTotal<1||!selectedSME">
                        CREATE
                    </a-button>

                    <vue-loaders-ball-beat v-if="loading" color="red" scale="1"></vue-loaders-ball-beat>

                    <p class="font-semibold text-muted text-center" v-if="error">{{ error }}</p>

                </a-form-item>
            </a-form>
        </sweet-modal>

        

        <a-row :gutter="24" type="flex">

            <vue-loaders-ball-beat v-if="loading" color="red" scale="1"></vue-loaders-ball-beat>

            <a-col :span="20" class="mb-24">
                <!-- Header Search Input -->
					<a-input-search class="header-search" :class="searchLoading ? 'loading' : ''" placeholder="Type here…" @search="onSearch" :loading='searchLoading' v-model="searchTerm">
						<svg slot="prefix" width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path fill-rule="evenodd" clip-rule="evenodd" d="M8 4C5.79086 4 4 5.79086 4 8C4 10.2091 5.79086 12 8 12C10.2091 12 12 10.2091 12 8C12 5.79086 10.2091 4 8 4ZM2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8C14 9.29583 13.5892 10.4957 12.8907 11.4765L17.7071 16.2929C18.0976 16.6834 18.0976 17.3166 17.7071 17.7071C17.3166 18.0976 16.6834 18.0976 16.2929 17.7071L11.4765 12.8907C10.4957 13.5892 9.29583 14 8 14C4.68629 14 2 11.3137 2 8Z" fill="#111827"/>
						</svg>
					</a-input-search>
            </a-col>

            <a-col :span="4" class="mb-24">
                <a-button type="primary" block style="margin-left: 9px;" @click="initCreateProjectChannelModal">
                    Add Allocation
                </a-button>
            </a-col>
            
        </a-row>

        

		<!-- Authors Table -->
		<a-row :gutter="24">

            <p v-if="smes.length<1">No items to display, check that you have data sources configured.</p>

			<!-- Authors Table Column -->
            <a-col :span="24">
                <a-table :columns="customerTableColumns" :data-source="allocations" :pagination="false" :loading="loading">
                    <span slot="Username" slot-scope="text, record">
                        
                        <i class="fa fa-user-circle-o" aria-hidden="true" style="margin-right:9px;"></i>
                        <span class="ml-2">{{ record.sme_id }}</span>
                    </span>
                    <span slot="first_name" slot-scope="text, record">
                        {{ record.first_name }}
                    </span>
                    <span slot="last_name" slot-scope="text, record">
                        {{ record.last_name }}
                    </span>
                    <span slot="email" slot-scope="text, record">
                        {{ record.email }}
                    </span>
                    <span slot="phone" slot-scope="text, record">
                        {{ record.phone }}
                    </span>

                    <span slot="created_at" slot-scope="text, record">
                        {{ record.created_at }}
                    </span>


                    <span slot="actions" slot-scope="text, record">
                        <a-button type="primary">View</a-button>
                    </span>
                </a-table>

			</a-col>
			<!-- / Authors Table Column -->

            

		</a-row>
		<!-- / Authors Table -->

        <br>

        <a-row>
            
         <a-pagination :disabled="searchTerm.length>2" :total="total_smes" :current="currentPage" :pageSize="10" @change="onPageChange" />
            
        </a-row>

       

	</div>
</template>

<script>

	// "Authors" table component.
	import CardProjectDisplay from '../components/Cards/CardProjectDisplay' ;

 	// "Projects" table component.
	import WidgetCustomer from '../components/Widgets/WidgetCustomer' ;

    import {fetchDataJSON, fetchPostJSON} from '../services/v1/services';
	

	export default ({
		components: {
			CardProjectDisplay,
			WidgetCustomer,
		},
        beforeCreate() {
			// Creates the form and adds to it component's "form" property.
			this.form = this.$form.createForm(this, { name: 'normal_login' });
		},
        async mounted() {



            const projectCustomersRes = await fetchDataJSON('aggregators/' + 'smes/list?page=' + this.currentPage , true);


            const projectAllocsRes = await fetchDataJSON('aggregators/smes/' + 'allocations/list?page=' + this.currentPage , true);


            const projectProductssRes = await fetchDataJSON('aggregators/products/' + 'list?page=' + this.currentPage , true);


            this.smes = projectCustomersRes.data.smes;

            this.allocations = projectAllocsRes.data.allocations;

            this.products = projectProductssRes.data.products;

            
            this.total_smes = 10 //projectCustomersRes.data.total.count;


            this.loading = false;
        },
        computed : {
            selectedTotal() {
                return this.selectedItems.reduce((acc, item) => acc + item.product_price * item.qty, 0);
            }
        },
        methods: {
            addItem() {
                const product = this.products.find(p => p.id === this.selectedProduct);
                this.selectedItems.push({id: product.id, name: product.name, product_price: product.product_price, qty: 1, description: product.name});
            },
            removeItem(index) {
                this.selectedItems.splice(index, 1);
            },
            async handleSubmitProject(e) {
                e.preventDefault();
                
                // validate form

                this.form.validateFields(async(err, values) => {
                    if ( !err ) {
                        console.log('Received values of form: ', values) ;

                        this.loading = true;

                        this.error = null;

                        const item = {
                            amount: this.selectedTotal,
                            item_data: this.selectedItems,
                        };

                        const res = await fetchPostJSON('aggregators/smes/' + this.selectedSME + '/vouchers/assign_item',item, true);

                        console.log(res);

                        if(res.Ok) {
                            this.$notification.success({
                                message: 'Success',
                                description: 'Allocation created successfully',
                            });

                            
                        }

                        else {
                            this.$notification.error({
                                message: 'Error',
                                description: 'failed to create allocation:' + res.status,
                            });
                        }

                        this.$refs.createprojectmodal.close();

                        const projectAllocsRes = await fetchDataJSON('aggregators/smes/' + 'allocations/list?page=' + this.currentPage , true);

                        this.allocations = projectAllocsRes.data.allocations;

                        this.loading = false;
                        this.selectedItems = [];
                        this.selectedSME= null;
                    }
                });

                // await this.addProject();
            },
            async onPageChange(page) {
                console.log(page);
                this.currentPage = page;

                this.loading = true;

                const projectCustomersRes = await fetchDataJSON('aggregators/' + 'smes/list?page=' + this.currentPage , true);

                this.smes = projectCustomersRes.data.smes;

                this.loading = false;
            },
            initCreateProjectChannelModal() {
                this.showNested = true;
                this.$refs.createprojectmodal.open();
            },
            closeNested() {
                this.showNested = false;
            },
            async onSearch(value) {
                this.searchLoading = true;

                console.log('searching', value);

                const projectCustomersRes = await fetchDataJSON('aggregators/' + 'smes/list?term=' + value , true);

                this.smes = projectCustomersRes.data.smes;

                this.searchLoading = false;
            },
        },
		data() {
			return {

                smes: [],

                allocations: [],

                loading: true,

                error: null,

                searchLoading: false,

                showNested: false,

                currentPage: 1,

                customerTableColumns: [
                    {
                        title: 'ID Number',
                        dataIndex: 'id_number',
                        key: 'id_number',
                        scopedSlots: { customRender: 'id_number' },
                    },
                    {
                        title: 'First Name',
                        dataIndex: 'first_name',
                        key: 'first_name',
                        scopedSlots: { customRender: 'first_name' },
                    },
                    {
                        title: 'Last Name',
                        dataIndex: 'last_name',
                        key: 'last_name',
                        scopedSlots: { customRender: 'last_name' },
                    },
                    {
                        title: 'Amount',
                        dataIndex: 'amount',
                        key: 'amount',
                        scopedSlots: { customRender: 'amount' },
                    },
                    
                    {
                        title: 'Created At',
                        dataIndex: 'created_at',
                        key: 'created_at',
                        scopedSlots: { customRender: 'created_at' },
                    },
                    /*{
                        title: 'Actions',
                        dataIndex: 'actions',
                        key: 'actions',
                        scopedSlots: { customRender: 'actions' },
                    },*/
                    
                ],


                newProjectName: '',

                newProjectDescription: '',

                total_smes: 0,

                searchTerm: '',

                voucherAmount: 0,
                selectedProduct: null,
                selectedSME: null,
                selectedItems: [],
                products: [],
                smes: [],
			}
		},
	})

</script>

<style lang="scss">

.selector-thick {
    width: 100%;

    height: 40px
}
</style>