<template>
    <!-- Salary Card -->
    <a-card :bordered="false" class="widget-2 h-full" style="margin-bottom: 13px; box-shadow: inset;">

        <template #title>

            <i :class="iconClass" aria-hidden="true"></i>

        </template>

        <div style="margin: 13px;">

            <a-descriptions :bordered="false" size="small" :column="1">

                <a-descriptions-item label="Title">{{ title }}</a-descriptions-item>
                <a-descriptions-item label="Type">{{ typeText}}</a-descriptions-item>
                
                <a-descriptions-item label="Code">{{ content }}</a-descriptions-item>

                <a-descriptions-item label="Status">
                    <a-badge :status="is_enabled ? 'success' : 'error'" />
                    {{ is_enabled ? 'Enabled' : 'Disabled' }}
                </a-descriptions-item>


            </a-descriptions>

            <a-button style="width: 100%;" type="primary" @click="viewProject">View</a-button>


        </div>



    </a-card>
    <!-- / Salary Card -->
</template>

<script>

export default ({
    props: {
        value: {
            type: Number,
            default: 0,
        },
        prefix: {
            type: String,
            default: "+$",
        },
        icon: {
            type: String,
            default: "",
        },
        title: {
            type: String,
            default: "",
        },
        content: {
            type: String,
            default: "",
        },
        project_type: {
            type: Number,
            default: "",
        },
        project_id: {
            type: String,
            default: "",
        },
        is_enabled: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        iconClass() {
            if (this.project_type == 1) {
                return "fa fa-tree icon";
            } else if (this.project_type == 2) {
                return "fa fa-bicycle icon";

            }
            else {
                return "fa fa-envelope icon";
            }
        },

        typeText() {
            if (this.project_type == 1) {
                return "Agriculture";
            } else if (this.project_type == 2) {
                return "E-mobility";

            }
            else {
                return "Other";
            }
        }
    },
    methods: {
        viewProject() {
            console.log("view project");
            // this.$router.push("/projects/" + this.project_id);
            if(this.is_enabled){
                this.$router.push("/comingsoon");
            }
            else {
                this.$message.error("Project is disabled");
            }
        }
    },
    data() {
        return {
        }
    },
})

</script>