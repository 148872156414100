<!-- 
	This is the tables page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

 <template>
	<div>
 

        <sweet-modal ref="createsmemodal" @close="closeNested" title="Create SME" width="50%">
            <a-form
                id="components-form-demo-normal-login"
                :form="form"
                class="login-form"
                @submit="handleSubmitSme"
                :hideRequiredMark="false"
                v-if="showNested"
            >
                <a-form-item class="mb-10" label="First Name" :colon="false">
                    <a-input 
                    v-decorator="[
                    'first_name',
                    { rules: [{ required: true, message: 'Please input sme first name' }] },
                    ]" placeholder="First Name" />
                </a-form-item>

                <a-form-item class="mb-10" label="Last Name" :colon="false">
                    <a-input 
                    v-decorator="[
                    'last_name',
                    { rules: [{ required: true, message: 'Please input sme last name' }] },
                    ]" placeholder="Last Name" />
                </a-form-item>


                <a-form-item class="mb-10" label="Phone" :colon="false">
                    <a-input 
                    v-decorator="[
                    'phone',
                    { rules: [{ required: true, message: 'Please input phone in international format' }] },
                    ]" placeholder="Phone" />
                </a-form-item>

                <a-form-item class="mb-10" label="ID Number" :colon="false">
                    <a-input 
                    v-decorator="[
                    'id_number',
                    { rules: [{ required: true, message: 'Please input  id number' }] },
                    ]" placeholder="ID Number" />
                </a-form-item>

                <a-form-item class="mb-10" label="KRA Pin" :colon="false">
                    <a-input 
                    v-decorator="[
                    'kra_pin',
                    { rules: [{ required: false, message: 'Please input  kra pin' }] },
                    ]" placeholder="KRA Pin" />
                </a-form-item>

                <a-form-item class="mb-10" label="Address" :colon="false">
                    <a-input 
                    v-decorator="[
                    'address',
                    { rules: [{ required: true, message: 'Please input  address' }] },
                    ]" placeholder="Address" />
                </a-form-item>

                <a-form-item class="mb-10" label="Date of Birth" :colon="false">
                    <a-input 
                    type="date"
                    v-decorator="[
                    'dob',
                    { rules: [{ required: true, message: 'Please input  date of birth' }] },
                    ]" placeholder="Date of Birth" />
                </a-form-item>


                <a-form-item class="mb-10">
                    <label>Gender</label>
                    <b-form-select v-model="selectedGender" :options="genders" value-field="name"
              		text-field="name" placeholder="Select Gender" class="selector-thick" required/>
                </a-form-item>

                
                <a-form-item class="mb-10">
                    <label>Project</label>
                    <b-form-select v-model="selectedProject" :options="projects" value-field="id"
              		text-field="name" placeholder="Select Project" class="selector-thick" required/>
                </a-form-item>


                <a-form-item class="mb-10">
                    <label>Language</label>
                    <b-form-select v-model="selectedLanguage" :options="languages" value-field="code"
              		text-field="name" placeholder="Select language" class="selector-thick" required/>
                </a-form-item>


                <a-form-item class="mb-10">
                    <label>County</label>
                    <b-form-select v-model="selectedCounty" :options="counties" value-field="id"
              		text-field="name" placeholder="Select County" class="selector-thick" required/>
                </a-form-item>

                <a-form-item class="mb-10">
                    <label>Send SMS</label>
                    <b-form-checkbox v-model="send_sms" value="true" class="selector-thick" required/>
                </a-form-item>

                 
                <a-form-item>
                    <a-button type="primary" block html-type="submit" class="login-form-button" :disabled="loading">
                        CREATE
                    </a-button>

                    <vue-loaders-ball-beat v-if="loading" color="red" scale="1"></vue-loaders-ball-beat>

                    <p class="font-semibold text-muted text-center" v-if="error">{{ error }}</p>

                </a-form-item>
            </a-form>
        </sweet-modal>

        

        <a-row :gutter="24" type="flex">

            <vue-loaders-ball-beat v-if="loading" color="red" scale="1"></vue-loaders-ball-beat>

            <a-col :span="20" class="mb-24">
                <!-- Header Search Input -->
					<a-input-search class="header-search" :class="searchLoading ? 'loading' : ''" placeholder="Type here…" @search="onSearch" :loading='searchLoading' v-model="searchTerm">
						<svg slot="prefix" width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path fill-rule="evenodd" clip-rule="evenodd" d="M8 4C5.79086 4 4 5.79086 4 8C4 10.2091 5.79086 12 8 12C10.2091 12 12 10.2091 12 8C12 5.79086 10.2091 4 8 4ZM2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8C14 9.29583 13.5892 10.4957 12.8907 11.4765L17.7071 16.2929C18.0976 16.6834 18.0976 17.3166 17.7071 17.7071C17.3166 18.0976 16.6834 18.0976 16.2929 17.7071L11.4765 12.8907C10.4957 13.5892 9.29583 14 8 14C4.68629 14 2 11.3137 2 8Z" fill="#111827"/>
						</svg>
					</a-input-search>
            </a-col>

            <a-col :span="4" class="mb-24">
                <a-button type="primary" @click="initCreateSMEModal" block style="margin-left: 9px;">
                    Add SME
                </a-button>
            </a-col>
            
        </a-row>

        

		<!-- Authors Table -->
		<a-row :gutter="24">

            <p v-if="smes.length<1">No items to display, check that you have data sources configured.</p>

			<!-- Authors Table Column -->
            <a-col :span="24">
                <a-table :columns="customerTableColumns" :data-source="smes" :pagination="false" :loading="loading">
                    <span slot="Username" slot-scope="text, record">
                        
                        <i class="fa fa-user-circle-o" aria-hidden="true" style="margin-right:9px;"></i>
                        <span class="ml-2">{{ record.username }}</span>
                    </span>
                    <span slot="first_name" slot-scope="text, record">
                        {{ record.first_name }}
                    </span>
                    <span slot="last_name" slot-scope="text, record">
                        {{ record.last_name }}
                    </span>
                    <span slot="email" slot-scope="text, record">
                        {{ record.email }}
                    </span>
                    <span slot="phone" slot-scope="text, record">
                        {{ record.phone }}
                    </span>

                    <span slot="created_at" slot-scope="text, record">
                        {{ record.created_at }}
                    </span>


                    <span slot="actions" slot-scope="text, record">
                        <a-button type="primary" @click="viewSme(record.id)">View</a-button>
                    </span>
                </a-table>

			</a-col>
			<!-- / Authors Table Column -->

            

		</a-row>
		<!-- / Authors Table -->

        <br>

        <a-row>
            
         <a-pagination :disabled="searchTerm.length>2" :total="total_smes" :current="currentPage" :pageSize="10" @change="onPageChange" />
            
        </a-row>

       

	</div>
</template>

<script>

	// "Authors" table component.
	import CardProjectDisplay from '../components/Cards/CardProjectDisplay' ;

 	// "Projects" table component.
	import WidgetCustomer from '../components/Widgets/WidgetCustomer' ;

    import {fetchDataJSON, fetchPostJSON} from '../services/v1/services';
	

	export default ({
		components: {
			CardProjectDisplay,
			WidgetCustomer,
		},
        beforeCreate() {
			// Creates the form and adds to it component's "form" property.
			this.form = this.$form.createForm(this, { name: 'normal_login' });
		},
        async mounted() {



            const projectCustomersRes = await fetchDataJSON('aggregators/' + 'smes/list?page=' + this.currentPage , true);

            this.smes = projectCustomersRes.data.smes;

            
            this.total_smes = 10 //projectCustomersRes.data.total.count;


            const projectsRes = await fetchDataJSON('aggregators/projects/list', true);

            this.projects = projectsRes.data.projects;


            const languageRes = await fetchDataJSON('aggregators/languages/list', true);

            this.languages = languageRes.data.languages;


            const countyRes = await fetchDataJSON('aggregators/counties/list', true);

            this.counties = countyRes.data.counties;


            this.loading = false;
        },
        methods: {

            viewSme(e){
                console.log(e);

                this.$router.push("/smes/" + e);
            },

            async handleSubmitSme(e) {
                e.preventDefault();
                
                // validate form

                this.form.validateFields(async(err, values) => {
                    if ( !err ) {
                        console.log('Received values of form: ', values) ;

                        this.loading = true;

                        this.error = null;

                        const item = {
                            first_name: values.first_name,
                            last_name: values.last_name,
                            email: values.email,
                            phone: values.phone,
                            language_code: this.selectedLanguage,
                            county_id: this.selectedCounty,
                            project_id: this.selectedProject,
                            id_number: values.id_number,
                            address: values.address,
                            gender: this.selectedGender,
                            kra_pin: values.kra_pin,
                            dob: values.dob,
                            send_sms: this.send_sms,
                        };

                        const res = await fetchPostJSON('aggregators/smes/create',item, true);

                        console.log(res);

                        this.loading = false;


                        if(res.Ok) {

                            console.log('ok')

                            
                        }
                        else{
                            this.$notification.error({
                            message: 'Error',
                            description: 'failed to create SME',
                            });
                        }

                        this.$refs.createsmemodal.close();

                        const projectCustomersRes = await fetchDataJSON('aggregators/' + 'smes/list?page=' + this.currentPage , true);

                        this.smes = projectCustomersRes.data.smes;

                    }
                });

                // await this.addProject();
            },
            async onPageChange(page) {
                console.log(page);
                this.currentPage = page;

                this.loading = true;

                const projectCustomersRes = await fetchDataJSON('aggregators/' + 'smes/list?page=' + this.currentPage , true);

                this.smes = projectCustomersRes.data.smes;

                this.loading = false;
            },
            initCreateSMEModal() {
                this.showNested = true;
                this.$refs.createsmemodal.open();
            }, 
            closeNested() {
                this.showNested = false;
            },
            async onSearch(value) {
                this.searchLoading = true;

                console.log('searching', value);

                const projectCustomersRes = await fetchDataJSON('aggregators/' + 'smes/list?term=' + value , true);

                this.smes = projectCustomersRes.data.smes;

                this.searchLoading = false;
            },
        },
		data() {
			return {

                smes: [],

                loading: true,

                error: null,

                searchLoading: false,

                showNested: false,

                currentPage: 1,

                customerTableColumns: [
                    {
                        title: 'Username',
                        dataIndex: 'username',
                        key: 'username',
                        scopedSlots: { customRender: 'Username' },
                    },
                    {
                        title: 'First Name',
                        dataIndex: 'first_name',
                        key: 'first_name',
                        scopedSlots: { customRender: 'first_name' },
                    },
                    {
                        title: 'Last Name',
                        dataIndex: 'last_name',
                        key: 'last_name',
                        scopedSlots: { customRender: 'last_name' },
                    },
                    {
                        title: 'Credit Limit',
                        dataIndex: 'current_credit_limit',
                        key: 'current_credit_limit',
                        scopedSlots: { customRender: 'current_credit_limit' },
                    },
                    {
                        title: 'Phone',
                        dataIndex: 'phone',
                        key: 'phone',
                        scopedSlots: { customRender: 'phone' },
                    },
                    {
                        title: 'Created At',
                        dataIndex: 'created_at',
                        key: 'created_at',
                        scopedSlots: { customRender: 'created_at' },
                    },
                    {
                        title: 'Actions',
                        dataIndex: 'actions',
                        key: 'actions',
                        scopedSlots: { customRender: 'actions' },
                    },
                    
                ],

                selectedCounty: null,

                selectedProject: null,

                selectedLanguage: null,

                selectedGender: null,

                newProjectName: '',

                newProjectDescription: '',

                total_smes: 0,

                searchTerm: '',

                projects: [],

                languages: [],

                counties: [],

                genders: [
                    {
                        'name': 'male'
                    },
                    {
                        'name': 'female'
                    }
                ],

                send_sms: true,

			}
		},
	})

</script>

<style lang="scss">

.selector-thick {
    width: 100%;

    height: 40px
}
</style>