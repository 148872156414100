import Vue from 'vue'
import VueRouter from 'vue-router'





















Vue.use(VueRouter)

let routes = [
	{
		// will match everything
		path: '*',
		component: () => import('../views/404.vue'),
	},
	{
		path: '/',
		name: 'Unk',
		redirect: '/sign-in',
	},
	{
		path: '/login',
		name: 'LoginDef',
		redirect: '/sign-in',
	},
	{
		path: '/aggregators/dashboard',
		name: 'Aggregators',
		layout: "dashboard",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
	},
	{
		path: '/layout',
		name: 'Layout',
		layout: "dashboard",
		component: () => import('../views/Layout.vue'),
	},
	
	{
		path: '/projects',
		name: 'Projects',
		layout: "dashboard",
		component: () => import('../views/Projects.vue'),
	},
	{
		path: '/groups',
		name: 'Groups',
		layout: "dashboard",
		component: () => import('../views/Groups.vue'),
	},
	{
		path: '/projects/:id',
		name: 'Project',
		layout: "dashboard",
		component: () => import('../views/Comingsoon.vue'),
	},
	{
		path: '/comingsoon',
		name: 'Comingsoon',
		layout: "dashboard",
		component: () => import('../views/Comingsoon.vue'),
	},
	{
		path: '/smes',
		name: 'SMEs',
		layout: "dashboard",
		component: () => import('../views/SMEs.vue'),
	},
	{
		path: '/smes/:id',
		name: 'SME',
		layout: "dashboard",
		component: () => import('../views/SME.vue'),
	},
	{
		path: '/vouchers',
		name: 'Voucher Allocations',
		layout: "dashboard",
		component: () => import('../views/Vouchers.vue'),
	},
	{
		path: '/cashallocations',
		name: 'Cash Allocations',
		layout: "dashboard",
		component: () => import('../views/CashAllocations.vue'),
	},
	{
		path: '/customers',
		name: 'Customers',
		layout: "dashboard",
		component: () => import('../views/Customers.vue'),
	},
	{
		path: '/sources',
		name: 'Sources',
		layout: "dashboard",
		component: () => import('../views/Sources.vue'),
	},
	{
		path: '/connectors',
		name: 'Connectors',
		layout: "dashboard",
		component: () => import('../views/Connectors.vue'),
	},
	{
		path: '/products',
		name: 'Products',
		layout: "dashboard",
		component: () => import('../views/Products.vue'),
	},
	{
		path: '/orders',
		name: 'Orders',
		layout: "dashboard",
		component: () => import('../views/Orders.vue'),
	},
	{
		path: '/billing',
		name: 'Billing',
		layout: "dashboard",
		component: () => import('../views/Billing.vue'),
	},
	{
		path: '/rtl',
		name: 'RTL',
		layout: "dashboard-rtl",
		meta: {
			layoutClass: 'dashboard-rtl',
		},
		component: () => import('../views/RTL.vue'),
	},
	{
		path: '/Profile',
		name: 'Profile',
		layout: "dashboard",
		meta: {
			layoutClass: 'layout-profile',
		},
		component: () => import('../views/Profile.vue'),
	},
	{
		path: '/sign-in',
		name: 'Sign-In',
		component: () => import('../views/Sign-In.vue'),
	},
	{
		path: '/sign-up',
		name: 'Sign-Up',
		meta: {
			layoutClass: 'layout-sign-up',
		},
		component: () => import('../views/Sign-Up.vue'),
	},
]

// Adding layout property from each route to the meta
// object so it can be accessed later.
function addLayoutToRoute( route, parentLayout = "default" )
{
	route.meta = route.meta || {} ;
	route.meta.layout = route.layout || parentLayout ;
	
	if( route.children )
	{
		route.children = route.children.map( ( childRoute ) => addLayoutToRoute( childRoute, route.meta.layout ) ) ;
	}
	return route ;
}

routes = routes.map( ( route ) => addLayoutToRoute( route ) ) ;

const router = new VueRouter({
	mode: 'hash',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior (to, from, savedPosition) {
		if ( to.hash ) {
			return {
				selector: to.hash,
				behavior: 'smooth',
			}
		}
		return {
			x: 0,
			y: 0,
			behavior: 'smooth',
		}
	}
})

export default router
